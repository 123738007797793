<template>
    <contentBox title="Contact" v-model="visible" :busy="sending">
        <b-overlay :show="sending || successful === true" variant="dark" :opacity="0.95" class="flex-fill flex-column overflow-auto">
            <template v-slot:overlay>
                <b-container v-if="sending" fluid class="text-center">
                    <b-spinner/><br/>
                    <span>Sending your message...</span>
                </b-container>
                <b-container fluid v-if="successful">
                    <b-alert variant="success" show>
                        <div class="text-center small"> 
                            <b-icon-check-circle font-scale="1.5"/><br/>
                            Your message has been delivered successfully.<br/>
                            We will reply soon!
                        </div>
                    </b-alert>
                </b-container>
            </template>
            <b-container>
                <b-row>
                    <b-col cols="12" md="3" class="d-none d-sm-inline text-center">
                        <b-icon-envelope font-scale="5"/>
                    </b-col>                
                    <b-col cols="12" md="9">
                        <p>
                            If you want to get in touch with us, please feel free to use this e-mail form to leave a message to us:                    
                        </p>
                        <b-form-group id="field-name" label = "Your name:" label-for="name" invalid-feedback="You have to enter your name." :state="stateName">
                            <b-input name="name" id="name" v-model="model.name" :state="stateName"/>
                        </b-form-group>
                        <b-form-group id="field-email" label = "Your e-mail address:" label-for="email" invalid-feedback="You have to enter a valid email address to make us able to reply."  :state="stateEMail">
                            <b-input name="name" type="email" id="email" v-model="model.email" :state="stateEMail"/>
                        </b-form-group>                            
                        <b-form-group id="field-message" label = "Your message:" label-for="message" invalid-feedback="You have to enter a message." :state="stateMessage">
                            <b-form-textarea type="message" id="message" rows="6" max-rows="10" v-model="model.message" :state="stateMessage"></b-form-textarea>
                        </b-form-group>                            
                        <div class="text-right mt-3">
                            <b-button variant="success" size="lg" @click="send"><b-icon-envelope-open/> send message</b-button>
                        </div>
                        <b-alert :show="!sending && successful === false" variant="danger">
                            <b-icon-exclamation-triangle/><br/>
                            Sorry, it seems there has been occured a technical problem during sending the message.<br/>
                            Please try again later!
                        </b-alert>
                    </b-col>
                </b-row>
            </b-container>
        </b-overlay>
    </contentBox>
</template>
<script>
import contentBox from '../components/content-box.vue'
export default {
    name: 'view-contact',
    components: {
        contentBox
    },
    data() {
        return {
            model: {
                name: '',
                email: '',
                message: ''
            },
            validationDone: false,
            sending: false,
            successful: null
        }
    },
    props: {
        value: null
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return (vm.value == 'contact');
            },
            set(v) {
                const vm = this;
                if (v === true) {
                    vm.$emit('input', 'contact');
                } else {
                    vm.$emit('input', null);
                }
            }
        },
        stateName() {
            const vm = this;
            if (!vm.validationDone) return null;
            return /\S/.test(vm.model.name);
        },
        stateEMail() {
            const vm = this;
            if (!vm.validationDone) return null;
            return (/\S/.test(vm.model.email) && vm.validateEMail(vm.model.email));
        },
        stateMessage() {
            const vm = this;
            if (!vm.validationDone) return null;
            return /\S/.test(vm.model.message);
        },
        isValid() {
            const vm = this;
            return (vm.stateName && vm.stateEMail && vm.stateMessage);
        }      
    },
    methods: {
        validateEMail(email) {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (email.match(validRegex)) {
                return true;                    
            } else {
                return false;                
            }
        },
        async send() {
            const vm = this;
            vm.validationDone = true;
            if (!vm.isValid) return;
            vm.successful = null;
            vm.sending = true;
            let response = await fetch('https://krypton-api.ndg.de/Public/EMail/Send', {
                method: 'POST',
                body: JSON.stringify(vm.model),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            vm.sending = false;
            if (!response.ok) {
                vm.successful = false;
            } else {
                vm.resetModel();
                vm.successful = true;
            }
        },
        resetModel() {
            const vm = this;
            vm.model = {
                name: '',
                email: '',
                message: ''                
            };
            vm.validationDone = false;
        }
    }
}
</script>
<style scoped>
</style>
