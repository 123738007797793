<template>
    <contentBox title="Listen to Soul Tsunami" v-model="visible" bgImage="daw_background.png">
        <div class="d-flex flex-fill flex-column overflow-hidden text-center">
            <iframe
                data-lnk-widget
                src="https://lnk.to/soultsunami/widget"
                style="width: 100%; height: 100%; padding: 0px; margin: 0px;"
                frameborder="0"
                allowtransparency="true">
            </iframe>                  
        </div>
    </contentBox>    
</template>
<script>
import contentBox from '../../components/content-box.vue'
export default {
    name: 'release-soul-tsunami',
    props: {
        value: null
    },
    components: {
        contentBox
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return (vm.value == 'teaser');
            },
            set(v) {
                const vm = this;
                if (v === true) {
                    vm.$emit('input', 'teaser');
                } else {
                    vm.$emit('input', null);
                }
            }
        }
    },    
    data() {
        return {            
        }
    }
}
</script>
<styles scoped>
</styles>
