<template>
    <div fluid class="mainButton pt-3 pb-3" role="button" @click="onClick">
        <b-icon :icon="icon"/>
        <span class="ml-2">{{ label }}</span>
    </div>
</template>
<script>
export default {
    name: 'main-button',
    data() {
        return {            
        }
    },
    props: {
        icon: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    methods: {
        onClick() {
            const vm = this;
            vm.$emit('click');
        }
    }
}
</script>
<style scoped>
.mainButton {
    width: 100%;
    color: #FAFAFC;
}
.mainButton:hover {
    width: 100%;
    color: #CACACA;
}
</style>
