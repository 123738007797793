<template>
    <b-container fluid class="text-center m-0 p-0">
        <img src="../assets/images/home-intro.png" class="d-none d-sm-inline" style="max-width: 480px;"/>        
        <img src="../assets/images/home-intro.png" class="d-inline d-sm-none" style="max-width: 240px;"/>
        <b-container fluid class="m-0 p-0">
            <b-row class="lightBg pt-2 pb-2">
                <b-col>
                    <a href="#" @click="openView('release|anyone')">
                        <strong class="text-dark">OUT NOW!</strong><br/>
                        <span class="highlight">ANYONE</span>
                    </a>
                </b-col>
            </b-row>
            <b-row align-h="center" class="buttonRow">
                <b-col cols="12" md="2" class="ml-2 mr-2">
                    <mainButton icon="music-player" label="project" @click="openView('project')"/>
                </b-col>
                <b-col cols="12" md="2" class="ml-2 mr-2">
                    <mainButton icon="music-note" label="music" @click="openView('music')"/>
                </b-col>
                <b-col cols="12" md="2" class="ml-2 mr-2">
                    <mainButton icon="music-player" label="contact" @click="openView('contact')"/>
                </b-col>
            </b-row>
            <b-row align-h="center" class="mt-2" style="font-size: 2rem;">
                <b-col>
                    <a href="https://www.facebook.com/greyncrowfield" target="_blank"><b-icon-facebook class="mt-0 mr-2"/></a>
                    <a href="https://www.instagram.com/greyncrowfield/" target="_blank"><b-icon-instagram class="ml-2"/></a>
                    <a href="https://www.tiktok.com/@greyncrowfield" target="_blank"><img src="../assets/images/tiktok_logo.svg" alt="Tiktok" style="height: 2rem;" class="imgIcon ml-3"/></a>
                    <a href="https://open.spotify.com/intl-de/artist/7i9DOnzFgyN8ZI5KIYaz2A" target="_blank"><img src="../assets/images/spotify_logo.svg" alt="Spotify" style="height: 2rem;" class="imgIcon ml-3"/></a>
                    <a href="https://music.apple.com/us/artist/grey-n-crowfield/1685943619" target="_blank"><img src="../assets/images/apple.svg" alt="Apple Music" style="height: 1.8rem;" class="imgIcon ml-3"/></a>
                    <a href="https://amazon.de/music/player/artists/B0C444R1FJ/grey-n-crowfield?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_yEzmt27Un9CUPeIxzYzQiCehC" target="_blank"><img src="../assets/images/amazon_music.svg" alt="Amazon Music" style="height: 1.8rem;" class="imgIcon ml-3"/></a>
                    <a href="https://soundcloud.com/grey-n-crowfield" target="_blank"><img src="../assets/images/soundcloud_logo.svg" alt="Soundcloud" style="height: 1.8rem;" class="imgIcon ml-3"/></a>
                    <a href="https://www.deezer.com/de/artist/212198887" target="_blank"><img src="../assets/images/deezer_logo.svg" alt="Deezer" style="height: 1.8rem;" class="imgIcon ml-3"/></a>
                    <a href="https://listen.tidal.com/artist/39166310" target="_blank"><img src="../assets/images/tidal_logo.svg" alt="TIDAL" style="height: 1.8rem;" class="imgIcon ml-3"/></a>
                </b-col>
            </b-row>                      
            <b-row align-h="center" class="mt-2" style="font-size: 1rem; font-family: 'NunitoSans'">
                <b-col>
                    <a href="#" @click="openView('privacy_policy')">Privacy Policy</a> | <a href="#" @click="openView('imprint')">Imprint</a>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>
<script>
import mainButton from '../components/main-button.vue'
export default {
    name: 'home',
    components: {
        mainButton
    },
    data() {
        return {            
        }
    },
    methods: {
        openView(viewCode) {
            const vm = this;
            vm.$emit('onView', viewCode);
        }
    }
}
</script>
<style scoped>
    .buttonRow {
        background-color: RGBA(0,0,0,0.5);
    }
    .lightBg {
        background-color: RGBA(255,255,255,0.5);        
    }
    .imgIcon {
        opacity: 0.9;
    }
    .imgIcon:hover {
        opacity: 0.7;
    }    
    .highlight {
        font-family: 'Grom';
        font-size: 1.5em !important;
        text-shadow: 1px 1px 2px blue, 0 0 1em blue, 0 0 0.2em blue;        
    }  

</style>