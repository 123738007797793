<template>
  <div id="app" class="flex-fill flex-column overflow-hidden text-center">
    <video id="bgVideo" autoplay muted loop poster="./assets/images/background-poster.jpg">
      <source src="./assets/videos/background.mp4" type="video/mp4">
    </video>
    <div class="flex-fill flex-column overflow-hidden content">
      <home v-if="currentView === null" @onView="openView"/>
      <viewProject v-if="currentView === 'project'" v-model="currentView"/>
      <viewTeaser v-if="currentView === 'teaser'" v-model="currentView"/>
      <viewReleaseSoulTsunami v-if="currentView === 'release|soul-tsunami'" v-model="currentView"/>
      <viewReleaseAnyone v-if="currentView === 'release|anyone'" v-model="currentView"/>
      <viewMusic v-if="currentView === 'music'" v-model="currentView"/>
      <viewContact v-if="currentView === 'contact'" v-model="currentView"/>
      <viewPrivacyPolicy v-if="currentView === 'privacy_policy'" v-model="currentView" :noCookieAlert="noCookieAlert"/>
      <viewImprint v-if="currentView === 'imprint'" v-model="currentView"/>
      <footer style="font-family: 'NunitoSans';">
        <cookieLaw
          v-if="!noCookieAlert"
          theme="dark-lime"
          buttonText="Accept"
          message="We use cookies on our website to understand how you use it. By clicking -Accept- you agree to their use"
          buttonClass="cookieButton"
          :buttonLink="cookieButtonLink"
          buttonLinkNewTab
          buttonLinkText="View details about the privacy policy"/>
      </footer>
    </div>
  </div>
</template>

<script>
const VUE_APP_URL = process.env.VUE_APP_URL;
import home from './views/home.vue'
import viewTeaser from './views/teaser.vue'
import viewReleaseSoulTsunami from './views/releases/soul-tsunami.vue'
import viewReleaseAnyone from './views/releases/anyone.vue'
import viewProject from './views/project.vue'
import viewMusic from './views/music.vue'
import viewContact from './views/contact.vue'
import viewPrivacyPolicy from './views/privacy_policy.vue'
import viewImprint from './views/imprint.vue'
import cookieLaw from 'vue-cookie-law'
export default {
  name: 'App',
  components: {
    home,
    viewTeaser,
    viewReleaseSoulTsunami,
    viewReleaseAnyone,
    viewProject,    
    viewMusic,
    viewContact,
    viewPrivacyPolicy,
    viewImprint,
    cookieLaw
  },  
  data() {
    return {      
      currentView: null,
      noCookieAlert: false
    }
  }, 
  computed: {
    cookieButtonLink() {
      return VUE_APP_URL + '?view=privacy_policy&nocookiealert=1';
    }
  },
  mounted() {
    const vm = this;
    var parameters = vm.$route.query;
    if (parameters.nocookiealert == "1") {
        vm.noCookieAlert = true;
    }
    if (parameters.view !== undefined) {
      vm.currentView = parameters.view;
    }
  },  
  methods: {
    openView(viewCode) {
      const vm = this;
      vm.currentView = viewCode;
    }
  }
}
</script>

<style>

@font-face {
  font-family: 'Grom';
  src: url('./assets/fonts/Grom.woff') format('woff'),
       url('./assets/fonts/Grom.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./assets/fonts/NunitoSans-Regular.woff') format('woff'),
       url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype');
}

@media only screen and (min-width: 600px) {

  body {
    font-size: 1.5em !important;
  }

}

html, body {
  background-color: #000000;
  margin: 0px auto;
  padding: 0px;
}
h1 {
  font-family: 'Grom';
  font-size: 1.5em !important;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}
#app {
  font-family: 'Grom';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin: 0px auto;
  padding: 0px;
}
#bgVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.content {
  position: fixed;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding: 0px;
}
a {
  color: #E0E0FF !important;
}
a:hover {
  color: #C0C0DF !important;
  text-decoration: none !important;
}
.cookieButton {
  background-color: RGB(0,0,0,0.75);
  padding: 1rem;
  color: RGB(0,150,0);
}
</style>
