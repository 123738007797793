<template>
    <contentBox title="COMING SOON!" v-model="visible" bgImage="daw_background.png">
        <b-container>
            <b-row class="text-center">
                <b-col>
                    <video width="480" style="max-width: 90%;" controls autoplay>
                        <source :src="getVideoUrl('teaser/Soul Tsunami - Teaser.mp4')" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>                    
                </b-col>
            </b-row>
        </b-container>
    </contentBox>
</template>
<script>
import contentBox from '../components/content-box.vue'
export default {
    name: 'view-teaser',
    components: {
        contentBox
    },
    data() {
        return {
        }
    },
    props: {
        value: null
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return (vm.value == 'teaser');
            },
            set(v) {
                const vm = this;
                if (v === true) {
                    vm.$emit('input', 'teaser');
                } else {
                    vm.$emit('input', null);
                }
            }
        }
    },
    methods: {
        getVideoUrl(v) {
            return require('../assets/videos/'+v)
        }        
    }
}
</script>
<style scoped>
    .box {
        border: 1px solid #CACACA;
        background-color: RGBA(0,0,0,0.7);
    }
</style>
