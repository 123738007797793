<template>
    <contentBox title="Music" v-model="visible" bgImage="daw_background.png">
        <b-container>
            <b-row class="text-center">
                <b-col>
                    Listen to our music on all well known streaming und download platforms:
                </b-col>
            </b-row>
            <b-row>
                <b-col v-for="p in portals" v-bind:key="p.code" cols="12" md="6" class="text-center">
                    <div class="box mt-3 p-3">
                        <b-link :href="p.link" target="_blank">
                            <div><img :src="getImgUrl(p.img)" style="width: 40%; height: 5vh;"></div>
                        </b-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </contentBox>
</template>
<script>
import contentBox from '../components/content-box.vue'
export default {
    name: 'view-music',
    components: {
        contentBox
    },
    data() {
        return {
            portals: [
                { code: 'spotify', text: 'Spotify', link: 'https://open.spotify.com/intl-de/artist/7i9DOnzFgyN8ZI5KIYaz2A', img: 'spotify.svg' },
                { code: 'applemusic', text: 'Apple Music', link: 'https://music.apple.com/us/artist/grey-n-crowfield/1685943619', img: 'apple_music.svg' },
                { code: 'amazonmusic', text: 'Amazon Music', link: 'https://amazon.de/music/player/artists/B0C444R1FJ/grey-n-crowfield?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_yEzmt27Un9CUPeIxzYzQiCehC', img: 'amazon_music.svg' },
                { code: 'soundcloud', text: 'Amazon Music', link: 'https://soundcloud.com/grey-n-crowfield', img: 'soundcloud.svg' },
                { code: 'deezer', text: 'Deezer', link: 'https://www.deezer.com/de/artist/212198887', img: 'deezer.svg' },
                { code: 'tidal', text: 'TIDAL', link: 'https://listen.tidal.com/artist/39166310', img: 'tidal.svg' }
            ]          
        }
    },
    props: {
        value: null
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return (vm.value == 'music');
            },
            set(v) {
                const vm = this;
                if (v === true) {
                    vm.$emit('input', 'music');
                } else {
                    vm.$emit('input', null);
                }
            }
        }
    },
    methods: {
        getImgUrl(pic) {
            return require('../assets/images/'+pic)
        }        
    }
}
</script>
<style scoped>
    .box {
        border: 1px solid #CACACA;
        background-color: RGBA(0,0,0,0.7);
    }
</style>
