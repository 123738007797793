<template>
    <contentBox title="Privacy policy" v-model="visible" :canClose="!noCookieAlert">
        <b-container>
            <b-row>
                <b-col cols="12" md="2" class="text-center">
                    <b-icon-info-circle font-scale="2"/>
                </b-col>                
                <b-col cols="12" md="9" style="font-size: 1rem;">
                    <strong>Introduction</strong><br/>
                    We take the privacy of users of our website and/or mobile app very seriously and are committed to protecting the information users provide to us in connection with their
                    use of our website and/or mobile app. Further, we are committed to protecting and using your information in accordance with applicable law.<br/>
                    <br/>
                    This Privacy Policy explains our practices regarding the collection, use, and disclosure of your information through the use of our digital assets when you access the Services through your devices.<br/>
                    <br/>
                    Please read the Privacy Policy carefully and make sure you fully understand our practices regarding your information before using our Services. If you have read this policy, fully understand it,
                    and do not agree with our practices, you must stop using our digital assets and services. By using our Services, you agree to the terms of this Privacy Policy.
                    Your continued use of the Services constitutes your acceptance of this Privacy Policy and any changes to it.<br/>
                    <br/>
                    In this Privacy Policy, you will learn:
                    <ul>
                        <li>How we collect data</li>
                        <li>What data we collect</li>
                        <li>Why we collect this data</li>
                        <li>Who we share the data with</li>
                        <li>Where the data is stored</li>
                        <li>How long the data is kept</li>
                        <li>How we protect the data</li>
                        <li>How we treat minors</li>
                        <li>Updates or changes to the privacy policy</li>
                    </ul>
                    <br/>
                    <strong>What data do we collect?</strong><br/>
                    Below is an overview of the data we may collect:<br/>
                    <ul>
                        <li>
                            Non-identified and non-identifiable information that you provide during the registration process or that is collected through your use of our Services ("Non-Personal Data").
                            Non-Personal Information does not identify who it was collected from. Non-Personal Information that we collect consists primarily of technical and aggregate usage information.                            
                        </li>
                        <li>
                            Individually Identifiable Information, which is any information that can be used to identify you or could be used to identify you with reasonable effort ("Personal Data").
                            Personally Identifiable Information we collect through our Services may include information requested from time to time, such as names, email addresses, addresses, phone numbers, IP addresses, and more. When we combine personal data with non-personal data, as long as it is in combination, we will treat it as personal data.
                        </li>
                    </ul>
                    <br/>
                    <strong>How do we collect data?</strong><br/>
                    Below are the main methods we use to collect data:
                    <ul>
                        <li>
                            We collect data when you use our services. So, when you visit our digital assets and use services, we may collect, record and store usage, sessions and related information.
                        </li>
                        <li>
                            We collect data that you provide to us yourself, for example, when you contact us directly through a communication channel (such as an email with a comment or feedback).
                        </li>
                        <li>
                            We may collect data from third-party sources, as described below.
                        </li>
                        <li>
                            We collect data that you provide to us when you sign in to our Services through a third-party provider, such as Facebook or Google.
                        </li>
                    </ul>
                    <br/>
                    <strong>Why do we collect this data?</strong><br/>
                    We may use your data for the following purposes:<br/>
                    <ul>
                        <li>
                            to provide and operate our Services
                        </li>
                        <li>
                            to develop, customize and improve our Services
                        </li>
                        <li>
                            to respond to your feedback, inquiries and requests and to provide assistance
                        </li>
                        <li>
                            to analyze request and usage patterns
                        </li>
                        <li>
                            for other internal, statistical and research purposes
                        </li>
                        <li>
                            to improve our data security and fraud prevention capabilities
                        </li>
                        <li>
                            to investigate violations and enforce our terms and policies and to comply with applicable law, regulation or governmental request
                        </li>
                        <li>
                            to investigate violations and enforce our terms and policies and to comply with applicable law, regulation or governmental request
                        </li>
                        <li>
                            to provide you with updates, news, promotional materials and other information related to our services.
                            For promotional emails, you can choose whether you want to continue receiving them. If not, simply click on the unsubscribe link in these emails.
                        </li>
                    </ul>
                    <br/>
                    <strong>Who do we share this data with?</strong><br/>
                    We may share your information with our service providers to operate our services (e.g., storing data via third-party hosting services, providing technical support, etc.).<br/>
                    <br/>
                    We may also disclose your information in the following circumstances:
                    <ul>
                        <li>I - to investigate, detect, prevent or take action regarding illegal activities or other misconduct</li>
                        <li>II -  to establish or exercise our rights of defense</li>
                        <li>III -  to protect our rights, property or personal safety and the safety of our users or the public</li>
                        <li>IV - in the event of a change of control of us or any of our affiliates (by way of a merger, acquisition or purchase of (substantially all of) our assets, et al. a.)</li>
                        <li>V - to collect, maintain and/or manage your information through authorized third party vendors (e.g., cloud service providers) as appropriate for business purposes</li>
                        <li>
                            VI - to work with third party vendors to improve your user experience. For the avoidance of doubt, we would like to point out that we may transfer or disclose non-personally
                            identifiable information to third parties or otherwise use it at our discretion.
                        </li>
                    </ul>
                    <br/>
                    <strong>Cookies and similar technologies</strong><br/>
                    When you visit or access our Services, we authorize third parties to use web beacons, cookies, pixel tags, scripts, and other technologies and analytics services ("Tracking Technologies").
                    These Tracking Technologies may allow third parties to automatically collect your information to improve the navigation experience on our digital assets, optimize their performance and
                    provide a tailored user experience, as well as for security and fraud prevention purposes.<br/>
                    <br/>
                    To learn more, please read our Cookie Policy.<br/>
                    <br/>
                    We will not share your email address or other personal information with advertisers or advertising networks without your consent.<br/>
                    <br/>
                    <strong>Where do we store the data?</strong><br/>
                    Please note that our companies and our trusted partners and service providers are located around the world. For the purposes explained in this Privacy Policy, we store and process any non-personal
                    data we collect in different jurisdictions.<br/>
                    <br/>
                    Personal data may be maintained, processed and stored in the United States, Ireland, South Korea, Taiwan, Israel, and to the extent necessary for the proper provision of our services and/or
                    required by law (as further explained below) in other jurisdictions.<br/>
                    <br/>
                    <strong>How long will the data be retained?</strong><br/>
                    Please note that we will retain the data we collect for as long as necessary to provide our Services, to comply with our legal and contractual obligations to you,
                    to resolve disputes, and to enforce our agreements.<br/>
                    <br/>
                    We may correct, amend or delete inaccurate or incomplete data at any time at our sole discretion.<br/>
                    <br/>
                    <strong>How do we protect the data?</strong><br/>
                    The hosting service for our digital assets provides us with the online platform through which we can offer our services to you. Your data may be stored via our hosting provider's data storage,
                    databases and general applications. It stores your data on secure servers behind a firewall and it provides secure HTTPS access to most areas of its services.<br/>
                    <br/>
                    All payment options offered by us and our hosting provider for our digital assets adhere to the PCI Security Standards Council's PCI-DSS (credit card industry data security standard) regulations.
                    This is the collaboration of brands such as Visa, MasterCard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of credit card data (including physical, electronic,
                    and procedural measures) by our store and service providers.<br/>            
                    <br/>
                    Notwithstanding the measures and efforts taken by us and our hosting provider, we cannot and do not guarantee absolute protection and security of the data you upload, post or otherwise disclose to us or others.<br/>
                    <br/>
                    For this reason, we ask that you establish strong passwords and, whenever possible, do not provide us or others with confidential information that you believe could cause you significant or
                    lasting harm if disclosed. In addition, because e-mail and instant messaging are not considered secure forms of communication, we ask that you not share confidential information through
                    either of these communication channels.<br/>
                    <br/>
                    <strong>How do we treat minors?</strong><br/>
                    The Services are not intended for users who have not reached the legal age of majority. We will not knowingly collect data from children. If you are under the age of majority, you should not download
                    or use the Services or provide any information to us.<br/>
                    <br/>
                    We reserve the right to request proof of age at any time so that we can verify whether minors are using our Services. In the event that we become aware that a minor is using our Services,
                    we may prohibit such users from accessing our Services and block them, and we may delete any information we hold about such user. If you have reason to believe that a minor has disclosed data to us,
                    please contact us as explained below.<br/>
                    <br/>
                    We will only use your personal data for the purposes set out in the Privacy Policy and only if we are satisfied that:
                    <ul>
                        <li>the use of your personal data is necessary to perform or enter into a contract (for example, to provide you with the Services themselves or customer service or technical support)</li>
                        <li>
                            the use of your personal data is necessary to comply with relevant legal or regulatory obligations or
                            the use of your personal data is necessary to support our legitimate business interests (provided that at all times this is done in a way that is proportionate and respects your data protection rights).
                        </li>
                    </ul>
                    <br/>            
                    As an EU resident, you may:
                    <ul>
                        <li>request confirmation as to whether or not personal data relating to you is being processed and request access to your stored personal data and certain additional information</li>
                        <li>request to receive personal data that you have provided to us in a structured, commonly used and machine-readable format</li>
                        <li>request the correction of your personal data stored by us</li>
                        <li>request the deletion of your personal data</li>
                        <li>object to the processing of your personal data by us</li>
                        <li>
                            request the restriction of the processing of your personal data or file a complaint with a supervisory authority.
                        </li>
                    </ul>
                    <br/>
                    Please note, however, that these rights are not unlimited and may be subject to our own legitimate interests and regulatory requirements. If you have general questions about the personal
                    information we collect and how we use it, please contact us as indicated below.<br/>
                    <br/>
                    In the course of providing the Services, we may transfer data across borders to affiliates or other third parties and from your country/jurisdiction to other countries/jurisdictions around
                    the world. By using the Services, you consent to the transfer of your data outside the EEA.<br/>
                    <br/>
                    If you are located in the EEA, your personal data will only be transferred to locations outside the EEA if we are satisfied that there is an adequate or comparable level of protection for
                    personal data. We will take appropriate steps to ensure that we have adequate contractual arrangements with our third parties to ensure that appropriate safeguards are in place so that the
                    risk of unlawful use, alteration, deletion, loss or theft of your personal data is minimized and that such third parties act at all times in accordance with applicable laws.<br/>
                    <br/>
                    <strong>Rights under California Consumer Protection Law.</strong><br/>                     
                    If you use the Services as a California resident, then you may be entitled under the California Consumer Privacy Act ("CCPA") to request access to and deletion of your information.<br/>
                    <br/>
                    To exercise your right to access and delete your information, please see below for how to contact us.<br/>
                    We do not sell users' personally identifiable information for the intent and purposes of the CCPA.<br/>
                    <br/>
                    <strong>Updates or Changes to the Privacy Policy</strong><br/>
                    We may revise this Privacy Policy from time to time in our sole discretion, and the version posted on the Site will always be current (see the "Status" statement).
                    We encourage you to periodically review this Privacy Policy for changes. In the event of material changes, we will post a notice to that effect on our Website.
                    Your continued use of the Services after we have posted a notice of changes on our Website will constitute your acknowledgement and acceptance of the changes to the
                    Privacy Policy and your agreement to be bound by the terms of those changes.<br/>
                    <br/> 
                    <strong>Contact</strong><br/>
                    If you have general questions about the Services or the information we collect about you and how we use it, please contact us at:<br/>
                    Name: Sven Hartmann<br/>
                    Address: Dortansstraße 22a, 41179 Mönchengladbach, Germany<br/>
                    <br/>                    
                    <strong>DISCLAIMER</strong><br/>
                    The information contained herein is not a substitute for legal advice and you should not rely on it alone. Specific requirements regarding legal terms and policies may vary from state
                    to state and/or jurisdiction to jurisdiction. As set forth in our Terms of Use, you are responsible for ensuring that your Services are permissible under the law that governs you and that you comply with it.<br/>
                    <br/>
                    To ensure that you are in full compliance with your legal obligations, we strongly recommend that you seek professional advice to better understand which requirements apply to you specifically.
                </b-col>
            </b-row>
        </b-container>
    </contentBox>
</template>
<script>
import contentBox from '../components/content-box.vue'
export default {
    name: 'view-contact',
    components: {
        contentBox
    },
    data() {
        return {            
        }
    },
    props: {
        value: null,
        noCookieAlert: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return (vm.value == 'contact');
            },
            set(v) {
                const vm = this;
                if (v === true) {
                    vm.$emit('input', 'contact');
                } else {
                    vm.$emit('input', null);
                }
            }
        }
    }
}
</script>
<style scoped>
</style>
