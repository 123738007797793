<template>
    <div class="contentBox d-flex flex-column flex-fill overflow-hidden">
        <div class="d-flex flex-column flex-shrink-0 pt-2">
            <b-container fluid class="border border-white border-top-0 border-left-0 border-right-0 border-bottom-1 pt-2">
                <b-row align-v="center">
                    <b-col cols="*">
                        &nbsp;
                    </b-col>
                    <b-col class="text-left">
                        <div>
                            <img src="../assets/images/greyncrowfield.png" class="d-none d-sm-inline" style="max-width: 20vw;"/>
                            <img src="../assets/images/greyncrowfield.png" class="d-inline d-sm-none" style="max-width: 30vw;"/>
                        </div>
                        <div style="font-size: rem;">
                            {{ title }}
                        </div>   
                    </b-col>
                    <b-col v-if="canClose" cols="*" class="mr-3">
                        <b-icon-x-circle v-if="busy" variant="dark" role="button" font-scale="1.5"/>
                        <b-icon-x-circle v-else variant="light" role="button" @click="close" font-scale="1.5"/>
                    </b-col>
                </b-row>
            </b-container>            
        </div>
        <div class="d-flex flex-column flex-fill overflow-auto pt-4 pl-0 pr-0 pb-2 text-left" :style="'font-family: NunitoSans; ' + contentAreaStyle">
            <slot/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'contentBox',
    data() {
        return {            
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        busy: {
            type: Boolean,
            default: false
        },
        value: null,
        bgImage: {
            type: String,
            default: null
        },
        canClose: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        },
        contentAreaStyle() {
            const vm = this;
            let style = "";
            if (vm.bgImage !== null) {
                style += "background-image: url('" + vm.getImgUrl(vm.bgImage) + "');"
                style += "background-repeat: no-repeat;"
                style += "background-size: 100%;"

            }
            return style;
        }
    },
    methods: {
        close() {
            const vm = this;
            vm.visible = false;
        },
        getImgUrl(pic) {
            return require('../assets/images/'+pic)
        }
    }  
}
</script>
<style scoped>

@media only screen and (max-width: 599px) {

.contentBox {
        position: fixed;
        top: 0vw;
        left: 0vw;
        right: 0vw;
        bottom: 0vw;
        color: #f1f1f1;
        background-color: RGBA(0,0,0,0.75);
        border: solid 1px RGBA(200,200,200,0.75);
    }

}

@media only screen and (min-width: 600px) {
.contentBox {
        position: fixed;
        top: 5vw;
        left: 25vw;
        right: 25vw;
        bottom: 5vw;
        color: #f1f1f1;
        background-color: RGBA(0,0,0,0.75);
        border: solid 1px RGBA(200,200,200,0.75);
    }

}

</style>
