<template>
    <contentBox title="Project" v-model="visible" bgImage="piano_background.png">
        <b-container fluid>             
            <b-row>
                <b-col class="pt-4">
                    <h1>&quot;...Music is the soundtrack of your life...!&quot;</h1>
                    <p>
                        In the year 2023 the studio project <strong>GREY N CROWFIELD</strong> was brought into being to produce electronic music including a wide variety of musical styles.
                    </p>
                    <p>
                        Producer and songwriter Sven looks back on 30 years of music creation experience and creates all songs in his home studio in Germany. 
                    </p>
                    <p>
                        In all Grey N Crowfield projects Sven recently cooperates with other singers and musicians.
                        A wide range of musical influences is the focus and each production should remain as an individual unique piece of music in the memory of the audience.
                    </p>
                </b-col>             
            </b-row>
        </b-container>
    </contentBox>
</template>
<script>
import contentBox from '../components/content-box.vue'
export default {
    name: 'view-project',
    components: {
        contentBox
    },
    data() {
        return {            
        }
    },
    props: {
        value: null
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return (vm.value == 'project');
            },
            set(v) {
                const vm = this;
                if (v === true) {
                    vm.$emit('input', 'project');
                } else {
                    vm.$emit('input', null);
                }
            }
        }
    }
}
</script>
<style scoped>
</style>
